html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
   border: 0;
   font-style: normal;
   font-weight: normal;
   margin: 0;
   outline: 0;
   padding: 0;
   vertical-align: baseline;
}

html {
   padding: 0;
   margin: 0;
}

body {
   padding: 0;
   margin: 0;
   font-size: 16px;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   text-rendering: optimizeLegibility;
}

body .ui-autocomplete {
   max-height: 8rem !important;
   overflow-y: auto !important;
   overflow-x: hidden !important;
}

img {
   border: none;
   display: block;
}

p {
   margin: 0;
}

a {
   text-decoration: none;
}

*:focus {
   outline: none;
}

* {
   box-sizing: border-box;
}

li {
   list-style: none;
}

input,
textarea,
button,
select {
   padding: 0;
   margin: 0;
   -webkit-appearance: none;
   -moz-appearance: none;
   appearance: none;
}

::-webkit-scrollbar {
   -webkit-appearance: none;
   background: #F1F1F1;
}

::-webkit-scrollbar:vertical {
   width: 0.35vw;
}

::-webkit-scrollbar-button:increment,
::-webkit-scrollbar-button {
   display: none;
}

::-webkit-scrollbar:horizontal {
   height: 1vw;
}

::-webkit-scrollbar-thumb {
   background-color: #C0C0C0;
   border: 0px solid #F1F1F1;
}

::-webkit-scrollbar-track {
   border-radius: 0px;
}

::placeholder,
::placeholder,
::placeholder {
   color: #A6ABC1;
}

:-ms-input-placeholder,
:-ms-input-placeholder,
:-ms-input-placeholder {
   color: #A6ABC1;
}

::-ms-input-placeholder,
::-ms-input-placeholder,
::-ms-input-placeholder {
   color: #A6ABC1;
}

input[type="checkbox"] {
   width: 0.8vw;
   height: 0.8vw;
   min-width: 0.8vw;
   min-height: 0.8vw;
   margin: 0px;
   cursor: pointer;
   background: #fff;
   border: 1px solid #D2D5E0;
   box-sizing: border-box;
   border-radius: 0.1vw;
   margin-right: 0.5vw;
}

input[type="checkbox"]:checked {
   -webkit-appearance: none;
   appearance: none;
   border: 1px solid #0091FF !important;
   background: #0091FF url("../images/icon-check.svg") no-repeat center / contain !important;
}

.flexbox {
   display: flex;
   flex-flow: row wrap;
}

@media screen and (max-width: 760px) {

   ::-webkit-scrollbar:vertical {
      width: 3px;
   }

   input[type="checkbox"] {
      width: 1em;
      height: 1em;
      min-width: 1em;
      min-height: 1em;
      margin-right: 0.5em;
      border-radius: 0.1em;
   }

}


@media screen and (max-width: 360px) {
   body {
      font-size: 14.75px;
   }
}

@media screen and (max-width: 320px) {
   body {
      font-size: 13.75px;
   }


}