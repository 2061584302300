@font-face 
{
    font-family: 'poppins-extrabold';
    src: url('../fonts/Poppins-ExtraBold.eot');
    src: url('../fonts/Poppins-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Poppins-ExtraBold.woff2') format('woff2'),
        url('../fonts/Poppins-ExtraBold.woff') format('woff'),
        url('../fonts/Poppins-ExtraBold.ttf') format('truetype'),
        url('../fonts/Poppins-ExtraBold.svg#Poppins-ExtraBold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
@font-face
{
	font-family:'poppins-bold';
	src:url('../fonts/Poppins-Bold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Poppins-Bold.woff') format('woff'),
		url('../fonts/Poppins-Bold.ttf')format('truetype'),
		url('../fonts/Poppins-Bold.svg#Poppins-Bold') format('svg');
	font-weight:normal;
	font-style:normal;
}
@font-face
{
	font-family:'poppins-semibold';
	src:url('../fonts/Poppins-SemiBold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Poppins-SemiBold.woff') format('woff'),
		url('../fonts/Poppins-SemiBold.ttf')format('truetype'),
		url('../fonts/Poppins-SemiBold.svg#Poppins-SemiBold') format('svg');
	font-weight:normal;
	font-style:normal;
}
@font-face{
	font-family:'poppins-medium';
	src:url('../fonts/Poppins-Medium.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Poppins-Medium.woff') format('woff'),
		url('../fonts/Poppins-Medium.ttf')format('truetype'),
		url('../fonts/Poppins-Medium.svg#Poppins-Medium') format('svg');
	font-weight:normal;
	font-style:normal;
}
@font-face
{
	font-family:'poppins-regular';
	src:url('../fonts/Poppins-Regular.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Poppins-Regular.woff') format('woff'),
		url('../fonts/Poppins-Regular.ttf')format('truetype'),
		url('../fonts/Poppins-Regular.svg#Poppins-Regular') format('svg');
	font-weight:normal;
	font-style:normal;
}
@font-face
{
	font-family:'poppins-light';
		src:url('../fonts/Poppins-Light.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Poppins-Light.woff') format('woff'),
		url('../fonts/Poppins-Light.ttf')format('truetype'),
		url('../fonts/Poppins-Light.svg#Poppins-Light') format('svg');
	font-weight:normal;
	font-style:normal;
}

