@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import 'codemirror/lib/codemirror';
@import 'codemirror/addon/hint/show-hint';

.swal2-container {
   font-family: Helvetica;
}

.requiredfield {
   border: 1px solid #eb5757 !important;
}

.img-grayscale {
   -webkit-filter: grayscale(100%);
   filter: grayscale(100%);
}

.cursor-forbidden {
   cursor: not-allowed !important;
}

.swal2-input {
   width: unset !important;
   margin: 0.5em !important;
}

.ngx-codemirror {
   border: 1px solid #ccc;
}

/* You can add global styles to this file, and also import other style files */
.player {
   width: 160px;
   height: 120px;
   margin: 0 auto;
}

/* Test */
a {
   color: #336699;
}

.element {
   width: 520px;
   height: 560px;
   box-shadow: 0px 0px 12px rgba(0, 255, 255, 0.5);
   border: 1px solid rgba(127, 255, 255, 0.25);
   font-family: Helvetica, sans-serif;
   text-align: center;
   line-height: normal;
   cursor: default;
}

.element:hover {
   box-shadow: 0px 0px 12px rgba(0, 255, 255, 0.75);
   border: 1px solid rgba(127, 255, 255, 0.75);
}

.element .number {
   position: absolute;
   top: 20px;
   right: 20px;
   font-size: 12px;
   color: rgba(127, 255, 255, 0.75);
}

.element .symbol {
   position: absolute;
   top: 40px;
   left: 0px;
   right: 0px;
   font-size: 60px;
   font-weight: bold;
   color: rgba(255, 255, 255, 0.75);
   text-shadow: 0 0 10px rgba(0, 255, 255, 0.95);
}

.element .details {
   position: absolute;
   bottom: 15px;
   left: 0px;
   right: 0px;
   font-size: 12px;
   color: rgba(0, 115, 255, 0.75);
}

button {
   color: rgba(255, 255, 255, 0.75);
   background-color: #010101;
   padding: 10px 15px;
   text-align: center;
   font-size: 15px;
   cursor: pointer;
   margin: 0 2px;
   margin-bottom: 10px;
}
/*
button:hover {
  background-color: rgba(123, 202, 255, 0.5);
}
*/
button:active {
   color: #000000;
   background-color: rgba(0, 170, 255, 0.75);
}

img {
   /* border-radius: 8px; */
}

/* input[type='text'],
select {
   width: 50%;
   padding: 2px 4px;
   margin: 5px 0;
   display: inline-block;
   border: 1px solid #ccc;
   border-radius: 2px;
   box-sizing: border-box;
} */

label {
   color: rgb(5, 5, 5);
   padding: 8px;
}

select {
   cursor: pointer;
}

/*03:22pm*/

.requiredfield {
   border: 1px solid #eb5757 !important;
}

.requiredtext {
   color: #eb5757 !important;
}

.requiredtext a {
   color: #eb5757 !important;
}

/*Notification */
#module-notification {
   display: none;
}

.box-notification {
   width: 23vw;
   position: fixed;
   top: 1vw;
   right: 2vw;
   background: #ffff;
   border-radius: 0.3vw;
   box-shadow: 0 0.5vw 1.5vw #21213829;
   z-index: 99;
   display: flex;
   padding: 1vw 1.5vw;
   align-items: center;
}

.icon-notification {
   border: 0.15vw solid transparent;
   margin-right: 0.7vw;
   border-radius: 0.3vw;
   width: 2.8vw;
   height: 2.8vw;
   background: url('/assets/images/icon-notification.svg') no-repeat center / contain;
}

.warning-notification .icon-notification {
   background: url('/assets/images/icon-warning-notification.svg') no-repeat center / contain !important;
}

.error-notification .icon-notification {
   background: url('/assets/images/icon-error-notification.svg') no-repeat center / contain !important;
}

.information-notification .icon-notification {
   background: url('/assets/images/icon-information-notification.svg') no-repeat center / contain !important;
}

.box-notification div {
   width: 14vw;
}

.box-notification div h2 {
   display: block;
   font: 1vw/1vw poppins-bold;
   color: #212138;
}

.box-notification div span {
   display: block;
   font: 0.85vw/1vw poppins-regular;
   color: #212138;
   padding-top: 0.25vw;
}

.button-close-notification {
   cursor: pointer;
   border: none;
   width: 0.85vw;
   height: 0.85vw;
   background: url('/assets/images/icon-close-notification.svg') no-repeat center / contain;
   position: absolute;
   right: 2vw;
   top: 2vw;
}

@viewport {
   width: device-width;
   zoom: 1;
}

@media screen and (max-width: 760px) {
   .box-notification {
      width: 94%;
      top: 2%;
      right: 3%;
      border-radius: 3px;
      padding: 1em 0.5em;
   }

   .icon-notification {
      margin-right: 1em;
      width: 2.2em;
      height: 2.2em;
   }

   .box-notification div h2 {
      font: 0.9em/1em poppins-bold;
   }

   .box-notification div {
      width: 15em;
   }

   .box-notification div span {
      font: 0.85em/1em poppins-regular;
      padding-top: 5px;
   }

   .button-close-notification {
      width: 1em;
      height: 1em;
      right: 1.5em;
      top: 2em;
   }
}
